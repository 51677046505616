export function Delayer() {
    let time = -1;
    return {
        delay(fn, timeout = 10) {
            clearTimeout(time);
            time = setTimeout(() => {
                fn();
            }, timeout);
        }
    };
}
